import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { components, operations } from '~/schema'
import { PayoutAvailabilityResponseStatus } from '~/schema'
import { useApiFetch } from '~/composables/api'

export type PayoutAvailabilityResponse =
  components['schemas']['PayoutAvailabilityResponse']
export type PayoutPostResponse = operations['requestPayout']

type PaymentAvailability = {
  isAvailable: boolean
  isInProcess: boolean
}

export function useOnDemandAvailability() {
  const fetch = useApiFetch()
  return useQuery({
    queryKey: ['payout-availability'],
    retry: false,
    queryFn({ signal }) {
      return fetch<PayoutAvailabilityResponse>(
        '/api/payments/payout/availability',
        { signal },
      )
    },
    select: ({ status }): PaymentAvailability => ({
      isAvailable: status === PayoutAvailabilityResponseStatus.AVAILABLE,
      isInProcess: status === PayoutAvailabilityResponseStatus.IN_PROCESS,
    }),
    enabled: import.meta.client,
  })
}

export function useOnDemandTransfer() {
  const fetch = useApiFetch()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn() {
      return fetch<PayoutPostResponse>('/api/payments/payout', {
        method: 'post',
      })
    },
    onSuccess: () => {
      // Invalidate and re-fetch
      queryClient.invalidateQueries({
        queryKey: ['payout-availability'],
      })
      queryClient.invalidateQueries({
        queryKey: ['payment-overview'],
      })
    },
  })
}
