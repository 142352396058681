export function usePaymentOverviewTracking() {
  const { $gtm, $i18n } = useNuxtApp()

  const getCommonParams = () => ({
    event: 'payments_overview',
    component: 'payments-overview',
    position: undefined,
    language: unref($i18n.locale),
    _clear: true,
  })

  function trackSeeAll() {
    $gtm.trackEvent({
      action: 'view',
      label: 'See all',
      category: 'payment statements',
      ...getCommonParams(),
    })
  }

  function trackOpenStatement(date: string) {
    $gtm.trackEvent({
      action: 'open',
      label: date.replace(/-/g, '/'),
      category: 'payment statements',
      ...getCommonParams(),
    })
  }

  function trackOpenAnnualReport(year: string | number) {
    $gtm.trackEvent({
      action: 'open',
      label: String(year),
      category: 'annual financial reports',
      ...getCommonParams(),
    })
  }

  function trackOpenUpcomingPayment() {
    $gtm.trackEvent({
      action: 'open',
      label: 'Total sum for upcoming payments',
      category: 'upcoming payment',
      ...getCommonParams(),
    })
  }

  return {
    trackSeeAll,
    trackOpenStatement,
    trackOpenAnnualReport,
    trackOpenUpcomingPayment,
  }
}
