<script setup lang="ts">
import { Card, Icon, Btn } from 'webcc-ui-components'
import { ANNUAL_REPORT_ANCHOR } from '~/components/payment/constants'
import { OtherFormType } from '~/schema'

const { data } = usePaymentOverview()
const { trackOpenAnnualReport } = usePaymentOverviewTracking()
const {
  isReportsEmpty,
  prevYearsReports,
  currency,
  isYearsReportsMoreThenFour,
} = unwrapNullableData(data)
const { downloadPdf } = useHandleFile()

function downloadAnnualFinancialReport(year: string | number) {
  trackOpenAnnualReport(year)
  downloadPdf(`/api/payments/annual/${year}/pdf`)
}
</script>

<template>
  <div :id="ANNUAL_REPORT_ANCHOR" data-id="AnnualFinancialReport" class="mb-4">
    <BasicHeading
      class="mb-4"
      title-classes="text-xl font-medium text-txt md:text-xl"
      :title="$t('components.annualReport.title')"
      :show-see-all-btn="false"
    />
    <BasicDataFallback
      v-if="isReportsEmpty"
      :title="$t('components.annualReport.emptyTitle')"
    >
      <p class="text-center text-base">
        {{ $t('components.annualReport.emptyDescription') }}
      </p>
    </BasicDataFallback>
    <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <Card
        v-for="yearItem in prevYearsReports"
        :key="yearItem.year"
        data-id="report-year"
        compact
        tabindex="0"
        class="flex grow justify-between gap-4 !p-4"
        @click="downloadAnnualFinancialReport(yearItem.year)"
        @keyup.enter="downloadAnnualFinancialReport(yearItem.year)"
      >
        <p class="text-txt-400">{{ yearItem.year }}</p>
        <div class="flex gap-4">
          <p class="font-medium">
            {{
              currencyConverter(
                toFixed({
                  number: yearItem.amount,
                  afterDotMaxNumber: 2,
                }),
                currency.symbol,
              )
            }}
          </p>
          <Icon color="#757C81" graphic="download" size="md" />
        </div>
      </Card>
    </div>

    <div
      v-if="isYearsReportsMoreThenFour"
      class="mt-8 flex flex-col items-center justify-center gap-4 md:flex-row md:justify-end"
    >
      <p class="text-center text-base leading-5 text-txt-100 md:text-left">
        {{ $t('components.annualReport.contactUsDescription') }}
      </p>
      <Btn
        :label="$t('components.common.contactUs')"
        @click="navigateTo(`${$ROUTE.CONTACT_US}?type=${OtherFormType.Other}`)"
      />
    </div>
  </div>
</template>
