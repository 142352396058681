<script setup lang="ts">
import { ProfileResponsePaymentSchedule } from '~/schema'

const { data } = usePaymentOverview()
const { overview, currencySymbol, paymentPeriodicity } =
  unwrapNullableData(data)
</script>

<template>
  <div data-id="payments-overview">
    <div class="flex flex-col gap-6 lg:flex-row">
      <div v-if="overview" class="relative bg-bgr-100 px-4 pb-8 pt-6 lg:w-1/2">
        <div class="absolute right-3 top-5">
          <PerformanceArrow
            :difference="
              Number(
                toFixed({
                  number: overview.growth || 0,
                  afterDotMaxNumber: 1,
                }),
              )
            "
          />
        </div>
        <div class="mb-7">
          <p class="mb-1 text-xs font-medium uppercase text-txt-500">
            {{ $t('components.common.overview') }}
          </p>
          <p class="text-xl font-medium text-txt-100">
            {{ $t('components.payments.yourEarnings') }}
          </p>
          <p class="text-base text-txt-100">
            {{ $t('components.payments.yearToDate') }}
          </p>
        </div>

        <p class="whitespace-nowrap text-right font-lato text-2xl font-bold">
          {{
            currencyConverter(
              toFixed({
                number: overview?.earnings || 0,
                afterDotMaxNumber: 2,
              }),
              currencySymbol,
            )
          }}
        </p>
      </div>

      <div class="lg:w-1/2">
        <PaymentOnDemand
          v-if="
            paymentPeriodicity === ProfileResponsePaymentSchedule.OnRequest &&
            $unleash.features['payments-pay-periodicity']
          "
        />
        <PaymentUpcoming v-else />
      </div>
    </div>
  </div>
</template>
