<script setup lang="ts">
import { Btn, MessageCard } from 'webcc-ui-components'

const { data } = usePaymentOverview()
const { upcomingPayments, currency } = unwrapNullableData(data)
const {
  data: availabilityData,
  isPending: availabilityPending,
  isFetching: availabilityFetching,
} = useOnDemandAvailability()

const {
  mutate: transferBalance,
  isSuccess: isTransferSuccess,
  isError: isTransferError,
  isPending: isTransferPending,
  isIdle: isTransferIdle,
  reset: resetTransferStatus,
} = useOnDemandTransfer()

const { isAvailable, isInProcess } = unwrapNullableData(availabilityData)

const isConfirmShown = ref<boolean>(false)
const convertedBalance = computed(() =>
  currencyConverter(
    toFixed({
      number: upcomingPayments.value.amount || 0,
      afterDotMaxNumber: 2,
    }),
    currency.value.symbol,
  ),
)
const resetComponentStatus = () => {
  isConfirmShown.value = false
  resetTransferStatus()
}
</script>
<template>
  <div data-id="on-demand">
    <div class="mb-3 flex items-center gap-1 text-xl font-medium text-txt">
      <p>{{ $t('components.payments.onDemandTitle') }}</p>

      <BasicTooltip>
        {{ $t('components.payments.onDemandMainTooltip') }}
      </BasicTooltip>
    </div>

    <div
      v-if="!isTransferError"
      :class="{
        'flex-col': isConfirmShown,
        'items-center': !isConfirmShown,
        'flex justify-between pb-4 border-b min-h-[55px]': true,
      }"
    >
      <p class="leading-[38px]">
        {{ convertedBalance }}
      </p>

      <div>
        <template v-if="!isConfirmShown">
          <BasicTooltip v-if="isInProcess">
            <p>{{ $t('components.payments.onDemandBtnTooltip') }}</p>
            <template #activator>
              <Btn
                data-id="on-demand-submit"
                :label="$t('components.payments.onDemandBtnTransferInProgress')"
                disabled
              />
            </template>
          </BasicTooltip>
          <Btn
            v-else
            data-id="on-demand-submit"
            :disabled="
              availabilityFetching || availabilityPending || !isAvailable
            "
            :loading="availabilityFetching || availabilityPending"
            :loading-text="$t('components.common.loading')"
            :label="$t('components.payments.onDemandBtnTransfer')"
            @click="isConfirmShown = true"
          />
        </template>

        <div
          v-else-if="(isConfirmShown && isTransferIdle) || isTransferPending"
          class="flex flex-col gap-12 md:gap-6"
        >
          <p class="text-base text-txt-100">
            {{ $t('components.payments.onDemandWarnMsg') }}
          </p>

          <div class="flex gap-4 justify-end">
            <Btn
              data-id="on-demand-refuse"
              :label="$t('components.payments.onDemandBtnRefuse')"
              variant="neutral"
              outlined
              @click="isConfirmShown = false"
            />
            <Btn
              data-id="on-demand-submit"
              :label="$t('components.payments.onDemandBtnTransfer')"
              :loading="isTransferPending"
              @click="transferBalance()"
            />
          </div>
        </div>

        <div
          v-else-if="isTransferSuccess"
          class="flex flex-col gap-12 md:gap-6"
        >
          <p class="text-base text-txt-100">
            {{ $t('components.payments.onDemandSuccessMsg') }}
          </p>

          <div class="flex gap-4 justify-end">
            <Btn
              :label="$t('components.payments.onDemandBtnGotIt')"
              data-id="on-demand-got-it"
              @click="isConfirmShown = false"
            />
          </div>
        </div>
      </div>
    </div>

    <MessageCard
      v-if="isTransferError"
      data-id="iban-warn-toast"
      variant="error"
      :closeable="false"
    >
      <div class="flex flex-col items-baseline gap-2">
        <p>{{ $t('components.payments.onDemandErrorMsg') }}</p>
        <div class="flex gap-2">
          <Btn
            :label="$t('components.common.contactUs')"
            @click="navigateTo($ROUTE.CONTACT_US)"
          />
          <Btn
            variant="neutral"
            :label="$t('components.payments.onDemandBtnDismiss')"
            @click="resetComponentStatus"
          />
        </div>
      </div>
    </MessageCard>
  </div>
</template>
