import { useQuery } from '@tanstack/vue-query'
import { useApiFetch } from '~/composables/api'
import type { components } from '~/schema'

export type PaymentOverviewResponse = components['schemas']['PaymentsOverview']
export type PaymentOverviewWithoutStatement = Omit<
  PaymentOverviewResponse,
  'statement' | 'years'
>
export type PaymentGroup = components['schemas']['PaymentGroup']
export type OverviewStatements = { [key: string]: PaymentGroup }

export type NormalizedPaymentOverview = PaymentOverviewWithoutStatement & {
  statementsCount: number
  statement: OverviewStatements
  prevYearsReports: components['schemas']['YearIncome'][]
  isYearsReportsMoreThenFour: boolean
  isReportsEmpty: boolean
  isUpcomingPaymentsEmpty: boolean
  isUpcomingPaymentNotEmpty: boolean
  currencySymbol: string
}

export function usePaymentOverview() {
  const fetch = useApiFetch()
  return useQuery({
    queryKey: ['payment-overview'],
    retry: false,
    queryFn({ signal }) {
      return fetch<PaymentOverviewResponse>('/api/payments/overview', {
        signal,
      })
    },
    select(payload): NormalizedPaymentOverview {
      const { statement, years, ...overviewData } = payload
      const serializedPayment = {
        statementsCount: 0,
        statement: {},
        prevYearsReports: years.slice(0, 4),
        isYearsReportsMoreThenFour: years.length > 4,
        isReportsEmpty: years.length === 0,
        isUpcomingPaymentsEmpty:
          !overviewData.upcomingPayments?.payments?.length,
        isUpcomingPaymentNotEmpty: Boolean(
          overviewData.upcomingPayments?.payments?.length,
        ),
        currencySymbol: overviewData.currency.symbol,
        ...overviewData,
      }
      serializedPayment.statement = statement.reduce(
        (acc, item) => ({
          ...acc,
          [item.date]: item,
        }),
        {},
      )
      serializedPayment.statementsCount = statement.length
      return serializedPayment
    },
  })
}
