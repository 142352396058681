<script setup lang="ts">
const { data } = usePaymentOverview()
const { trackOpenUpcomingPayment } = usePaymentOverviewTracking()
const { upcomingPayments, isUpcomingPaymentNotEmpty, currencySymbol } =
  unwrapNullableData(data)
const [isUpcomingPaymentsModalOpened, toggleUpcomingModal] = useToggle(false)
const toggleUpcomingPaymentsModal = () => {
  if (!isUpcomingPaymentNotEmpty.value) return
  if (!isUpcomingPaymentsModalOpened.value) trackOpenUpcomingPayment()
  toggleUpcomingModal()
}
</script>

<template>
  <div data-id="upcoming-payment-container">
    <div
      data-id="upcoming-payments-tooltip-container"
      class="mb-3 flex items-center gap-1 text-xl font-medium text-txt"
    >
      <span>{{ $t('components.payments.upcomingPayments') }}</span>
      <BasicTooltip>
        {{ $t('components.payments.tooltip') }}
      </BasicTooltip>
    </div>
    <PaymentItem
      :amount="upcomingPayments?.amount!"
      :currency-symbol="currencySymbol"
      :is-upcoming-payments-not-empty="isUpcomingPaymentNotEmpty"
      @click="toggleUpcomingPaymentsModal"
    />

    <LazyPaymentUpcomingPopup
      v-if="isUpcomingPaymentsModalOpened"
      @close="toggleUpcomingPaymentsModal"
    />
  </div>
</template>
