<script setup lang="ts">
import { PAGE_SIZE } from '~/composables/payments-statements'

const { t } = useI18n()

definePageMeta({
  layout: 'loggedin',
  middleware: ['payments-middleware'],
  title: 'components.common.paymentsOverview',
})

useAccommodationsQuery()
const { data, isError, suspense } = usePaymentOverview()
const { trackSeeAll, trackOpenStatement } = usePaymentOverviewTracking()
try {
  await suspense()
} catch (e) {}

const { statementsCount, statement } = unwrapNullableData(data)

const { $ROUTE } = useNuxtApp()
const selectedStatementDate = ref('')

const breadcrumbs = computed(() => {
  return [
    {
      url: $ROUTE.INDEX,
      label: t('components.common.home'),
    },
    {
      label: t('components.common.paymentsOverview'),
    },
  ]
})
const showPagination = computed(() => {
  return statementsCount.value > PAGE_SIZE
})
const statementsList = computed(() => {
  return Object.fromEntries(
    Object.entries(statement.value || {}).slice(0, PAGE_SIZE),
  )
})
const rest = computed(() => {
  return PAGE_SIZE > statementsCount.value
    ? statementsCount.value
    : statementsCount.value - PAGE_SIZE
})

function seeAllStatements() {
  trackSeeAll()
  navigateTo($ROUTE.PAYMENTS_STATEMENTS)
}

function openStatement(date: string) {
  trackOpenStatement(date)
  selectedStatementDate.value = date
}
</script>

<template>
  <main data-id="payments-overview" class="flex-full-h">
    <BasicBreadcrumbs :items="breadcrumbs" />

    <BasicPageContent container-class="sm:mx-4 md:mx-0 mb-8 md:mb-20">
      <OnDevices rule=">=md">
        <BasicPageHeading
          class="mb-4 md:mb-8 md:mt-6 xl:mb-4"
          :title="$t('components.common.paymentsOverview')"
        />
      </OnDevices>

      <PaymentIBANWarning
        v-if="$auth.profile.permissions?.ViewPayments && !$auth.profile.iban"
        class="my-6 md:mt-0 md:mb-6"
      />

      <BasicContentWrapper :is-error="isError" class="flex flex-col gap-8 mb-8">
        <div class="flex flex-col gap-8">
          <PaymentOverview />

          <div class="grid w-full gap-y-4">
            <BasicHeading
              class="mb-0"
              title-classes="text-xl font-medium text-txt md:text-xl"
              :title="$t('components.common.statements')"
              show-see-all-btn
              @see-all="seeAllStatements"
            />

            <PaymentStatementList
              :statements-list="statementsList"
              @payment-click="openStatement"
            />

            <BasicPagination
              v-if="showPagination"
              :total="statementsCount"
              :rest="rest"
            />
          </div>

          <PaymentAnnualFinancialReport />
        </div>
      </BasicContentWrapper>

      <PaymentDetails />
    </BasicPageContent>

    <LazyPaymentStatementPopup
      :date="selectedStatementDate"
      @close="selectedStatementDate = ''"
    />
  </main>
</template>
